import { api } from "@/lib/api";
import { fetchWithOrgHeader } from "@/lib/fetchWithOrgHeader";
import { formatDateTimeInTimezone } from "@/lib/utils";
import type {
    IdentityDto,
    MemberDTO,
    OrganizationDTO,
    PublicOrganizationDTO,
    SignInOtpDto,
    SignInPasswordDto,
    SignUpDto,
    UserDTO,
} from "@/types/dto";
import { StorageSerializers, useLocalStorage } from "@vueuse/core";
import { jwtDecode } from "jwt-decode";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { toast } from "vue-sonner";
import { getBaseAppUrl, getOrganizationBaseUrl } from "../lib/config";

export const useAuthStore = defineStore("auth", () => {
    // Storages
    const _accessToken = useLocalStorage<string | null>("accessToken", null);
    const _refreshToken = useLocalStorage<string | null>("refreshToken", null);
    // User
    const user = useLocalStorage<UserDTO | null>("user", null, {
        serializer: StorageSerializers.object,
    });
    const isSystemAdmin = useLocalStorage<boolean>("isSystemAdmin", false, {
        serializer: StorageSerializers.boolean,
    });
    const selfMember = useLocalStorage<MemberDTO | null>("selfMember", null, {
        serializer: StorageSerializers.object,
    });
    const userOrganization = useLocalStorage<OrganizationDTO | null>("userOrganization", null, {
        serializer: StorageSerializers.object,
    });
    // Organization
    const selectedOrganization = useLocalStorage<PublicOrganizationDTO | null>("selectedOrganization", null, {
        serializer: StorageSerializers.object,
    });

    // States
    const authenticated = ref(false);
    const loading = ref(false);
    const tokenExpirationTime = ref<number | null>(null);

    // Getters
    const userRole = computed(() => user.value?.role);
    const currentEstablishmentId = computed(() =>
        user.value?.establishment == null ? "est_default" : user.value?.establishment,
    );
    const isHolder = computed(() => selfMember.value?.isHolder ?? false);

    // Watch for changes in the access token
    watch(
        _accessToken,
        newToken => {
            if (newToken) {
                const decodedToken = jwtDecode(newToken) as { exp: number };
                tokenExpirationTime.value = decodedToken.exp * 1000; // Convert to milliseconds
            } else {
                tokenExpirationTime.value = null;
            }
        },
        { immediate: true },
    );

    // Public methods
    function getAccessToken(): string | null {
        return _accessToken.value;
    }

    async function initAuth() {
        if (_accessToken.value && _refreshToken.value) {
            authenticated.value = true;
            await refreshTokens();
            await fetchSelfMember();
        } else {
            authenticated.value = false;
        }
        startExpirationCheck();
    }

    async function checkAuthStatus(): Promise<boolean> {
        if (!authenticated.value) {
            return false;
        }
        if (tokenExpirationTime.value && Date.now() >= tokenExpirationTime.value) {
            return refreshTokens();
        }
        return true;
    }

    async function signInPassword({ email, password }: SignInPasswordDto): Promise<boolean> {
        console.info("Signing in via password...");
        loading.value = true;

        try {
            const data = await fetchWithOrgHeader("/auth/sign-in/password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password }),
            });
            const success = handleAuthSuccess(data);
            if (success) {
                await fetchSelfMember();
            }
            return success;
        } catch (error) {
            throw handleAuthError(error);
        } finally {
            loading.value = false;
        }
    }

    async function signInOtp({ email, otp }: SignInOtpDto): Promise<boolean> {
        console.info("Signing in via otp...");
        loading.value = true;

        try {
            const data = await fetchWithOrgHeader("/auth/sign-in/otp", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, otp }),
            });
            const success = handleAuthSuccess(data);
            if (success) {
                await fetchSelfMember();
            }
            return success;
        } catch (error) {
            throw handleAuthError(error);
        } finally {
            loading.value = false;
        }
    }

    async function sendOtp(identifier: string) {
        console.info("Sending OTP...");
        loading.value = true;

        try {
            return await fetchWithOrgHeader("/auth/send-otp", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ identifier, channel: "email" }),
            });
        } catch (error) {
            throw handleFetchError(error);
        } finally {
            loading.value = false;
        }
    }

    async function signUp(dto: SignUpDto) {
        console.info("Signing up...");
        loading.value = true;

        try {
            return await fetchWithOrgHeader("/auth/sign-up", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dto),
            });
        } catch (error) {
            throw handleFetchError(error);
        } finally {
            loading.value = false;
        }
    }

    async function fetchSelfMember() {
        try {
            const data = await fetchWithOrgHeader("/me/member", {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });
            selfMember.value = data;
        } catch (error) {
            console.error("Failed to fetch self member:", error);
            toast.error("Failed to fetch user details. Please try again.");
        }
    }

    async function updateUser({ firstName, lastName, email }: { firstName: string; lastName: string; email: string }) {
        if (!user.value) {
            toast.error("Aucun utilisateur trouvé pour la modification. Veuillez vous reconnecter.");
            return false;
        }

        try {
            const data = await fetchWithOrgHeader(`/users/me`, {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    email,
                }),
            });
            user.value = data;
        } catch (error) {
            throw handleFetchError(error);
        }

        toast.success("Votre profil a été mis à jour avec succès.");
        await refreshTokens();
        return true;
    }

    async function refreshTokens(): Promise<boolean> {
        if (!_refreshToken.value) {
            console.info("No refresh token found");
            signOut();
            return false;
        }

        console.info("Refreshing token...");
        loading.value = true;

        try {
            const headers = new Headers({
                "Content-Type": "application/json",
                "x-organization-id": userOrganization.value!.id,
            });

            const response = await fetch(api("/auth/refresh-token"), {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ refreshToken: _refreshToken.value }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data: IdentityDto = await response.json();

            return handleAuthSuccess(data);
        } catch (error) {
            handleAuthError(error);
            return false;
        } finally {
            loading.value = false;
        }
    }

    function signOut() {
        console.info("Signing out...");
        // Clear all auth-related localStorage
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        localStorage.removeItem("selfMember");

        // Reset reactive refs
        _accessToken.value = null;
        _refreshToken.value = null;
        user.value = null;
        selfMember.value = null;
        userOrganization.value = null;
        authenticated.value = false;
        loading.value = false;
        tokenExpirationTime.value = null;
    }

    async function forgotPassword(email: string) {
        try {
            return await fetchWithOrgHeader("/auth/forgot-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });
        } catch (error) {
            throw handleFetchError(error);
        }
    }

    async function resetPassword(resetPasswordToken: string, password: string) {
        try {
            return await fetchWithOrgHeader("/auth/reset-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ resetPasswordToken, password }),
            });
        } catch (error) {
            throw handleFetchError(error);
        }
    }

    function signInOrganization(organization: OrganizationDTO) {
        console.info("Signing in via organization...");
        selectedOrganization.value = organization;
    }

    function exitSysAdminMode() {
        console.info("Exiting system admin mode...");
        selectedOrganization.value = userOrganization.value;
    }

    function setSelectedOrganization(organization: PublicOrganizationDTO) {
        console.info("Setting selected organization...");
        selectedOrganization.value = organization;
    }

    function clearSelectedOrganization() {
        console.info("Clearing selected organization...");
        selectedOrganization.value = null;
    }

    function redirectToOrganization(organization: PublicOrganizationDTO) {
        const orgUrl = getOrganizationBaseUrl(organization);
        window.location.href = orgUrl.toString();
    }

    function redirectToBase() {
        const baseUrl = getBaseAppUrl();
        window.location.href = baseUrl.toString();
    }

    async function searchOrganizationsDirectory(query: string): Promise<PublicOrganizationDTO[]> {
        return fetchWithOrgHeader<PublicOrganizationDTO[]>(`/organizations/directory?q=${query}`);
    }

    async function getOrganizationByKey(key: string) {
        return fetchWithOrgHeader<PublicOrganizationDTO>(`/organizations/directory/key/${key}`);
    }

    // Utility functions
    function formatDateTimeInOrganizationTimeZone(date: string) {
        if (!userOrganization.value) return date;

        return formatDateTimeInTimezone(date, userOrganization.value.timeZone);
    }

    function formatDateTimeInUserTimeZone(date: string) {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return formatDateTimeInTimezone(date, tz);
    }

    // Private functions

    function handleFetchError(error: any) {
        console.error(error);
        return error;
    }

    function handleAuthError(error: any) {
        console.info("Authentication failed");
        console.error(error);
        toast.error(error.message);
        signOut();
        return error;
    }

    function handleAuthSuccess(data: IdentityDto): boolean {
        // First clear any existing data to ensure clean state
        signOut();

        // Then set the new data
        _accessToken.value = data.accessToken;
        _refreshToken.value = data.refreshToken;
        user.value = data.user as UserDTO;
        isSystemAdmin.value = data.isSystemAdmin;
        userOrganization.value = data.organization as OrganizationDTO;
        authenticated.value = true;
        loading.value = false;

        console.info("Authentication succeeded");
        return true;
    }

    function startExpirationCheck() {
        setInterval(async () => {
            if (tokenExpirationTime.value && Date.now() >= tokenExpirationTime.value - 60000) {
                // Refresh 1 minute before expiration
                await refreshTokens();
            }
        }, 30000); // Check every 30 seconds
    }

    // Initialize auth state
    initAuth();

    // Exports
    return {
        authenticated,
        user,
        selfMember,
        userOrganization,
        isSystemAdmin,
        loading,
        userRole,
        currentEstablishmentId,
        isHolder,
        selectedOrganization,
        getAccessToken,
        signInPassword,
        signInOtp,
        sendOtp,
        signUp,
        signOut,
        forgotPassword,
        resetPassword,
        updateUser,
        refreshTokens,
        checkAuthStatus,
        initAuth,
        fetchSelfMember,
        signInOrganization,
        setSelectedOrganization,
        clearSelectedOrganization,
        exitSysAdminMode,
        redirectToOrganization,
        redirectToBase,
        searchOrganizationsDirectory,
        getOrganizationByKey,
        formatDateTimeInOrganizationTimeZone,
        formatDateTimeInUserTimeZone,
    };
});
