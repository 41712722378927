import AppLayout from "@/layouts/AppLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import { useAuthStore } from "@/stores/auth";
import { createRouter, createWebHashHistory } from "vue-router";
import { authGuard } from "./auth-guard";
import { organizationGuard } from "./organization-guard";

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [
        // Root app routes
        {
            name: "selectOrganization",
            path: "/welcome",
            component: () => import("@/components/organizations/OrganizationPicker.vue"),
            meta: { requiresAuth: false, requiresOrg: false, layout: EmptyLayout },
        },
        {
            name: "onboarding",
            path: "/onboarding",
            component: () => import("@/views/OnboardingView.vue"),
            meta: { requiresAuth: false, requiresOrg: false, layout: EmptyLayout },
        },
        // Unregistered routes
        {
            name: "website",
            path: "/discover",
            meta: { requiresAuth: false, layout: EmptyLayout },
            component: () => import("@/views/website/DiscoverView.vue"),
        },
        {
            name: "login",
            path: "/login",
            component: () => import("@/views/LoginView.vue"),
            props: route => ({
                redirect: route.query.redirect,
                email: route.query.email,
            }),
            meta: { requiresAuth: false, layout: EmptyLayout },
        },
        {
            name: "register",
            path: "/register",
            component: () => import("@/views/RegisterView.vue"),
            meta: { requiresAuth: false, layout: EmptyLayout },
        },
        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () => import("@/views/auth/ForgotPasswordView.vue"),
            meta: { requiresAuth: false, layout: EmptyLayout },
        },
        {
            path: "/reset-password",
            name: "reset-password",
            component: () => import("@/views/auth/ResetPasswordView.vue"),
            meta: { requiresAuth: false, layout: EmptyLayout },
        },
        // App routes
        {
            name: "index",
            path: "/",
            components: {
                default: () => import("@/views/IndexView.vue"),
            },
            meta: { requiresAuth: true, layout: AppLayout },
        },
        {
            path: "/profile",
            name: "profile",
            component: () => import("@/views/profile/ProfileView.vue"),
            meta: { requiresAuth: true, layout: AppLayout },
        },
        {
            name: "delegations",
            path: "/delegations",
            components: {
                default: () => import("@/views/DelegationsView.vue"),
            },
            meta: { requiresAuth: true, layout: AppLayout },
        },
        {
            name: "recovery-tokens",
            path: "/recovery-tokens",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/RecoveryTokensView.vue"),
            },
        },
        {
            name: "registry",
            path: "/registry",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/registry/RegistryView.vue"),
            },
        },
        {
            name: "recovery-tokens-consume",
            path: "/recovery-tokens/:id/consume",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/recovery-tokens/ConsumeRecoveryTokenView.vue"),
            },
        },
        {
            name: "communication",
            path: "/communications",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/CommunicationsView.vue"),
            },
        },
        {
            name: "documents",
            path: "/documents",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/documents/DocumentsView.vue"),
            },
        },
        {
            name: "orders",
            path: "/orders",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/orders/OrdersView.vue"),
            },
        },
        {
            name: "order-read",
            path: "/orders/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/orders/OrderReadView.vue"),
            },
        },
        {
            name: "accountability",
            path: "/accountability",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/accountability/AccountabilityOrdersView.vue"),
            },
        },
        {
            name: "members",
            path: "/members/all",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/members/MembersView.vue"),
            },
        },
        {
            name: "member-create",
            path: "/members/create",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/members/MemberCreate.vue"),
            },
        },
        {
            name: "participants",
            path: "/members/participants",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/members/participants/ParticipantsView.vue"),
            },
        },
        {
            name: "participants-read",
            path: "/members/participants/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/members/participants/ParticipantsReadView.vue"),
            },
        },
        {
            name: "holders",
            path: "/members/holders",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/members/holders/HoldersView.vue"),
            },
        },
        {
            name: "holders-read",
            path: "/members/holders/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            component: () => import("@/views/members/holders/HoldersReadView.vue"),
        },
        {
            name: "service-create",
            path: "/services/create",
            meta: { requiresAuth: true, layout: EmptyLayout },
            component: () => import("@/views/activities/blueprints/BlueprintCreateView.vue"),
        },
        {
            name: "period-create",
            path: "/periods/create",
            meta: { requiresAuth: true, layout: EmptyLayout },
            component: () => import("@/views/periods/PeriodCreateView.vue"),
        },
        {
            name: "period-manage-services",
            path: "/periods/:id/manage-services",
            meta: { requiresAuth: true, layout: EmptyLayout },
            component: () => import("@/views/periods/PeriodManageServices.vue"),
        },
        {
            name: "period-plan-next",
            path: "/periods/:id/plan-next",
            meta: { requiresAuth: true, layout: EmptyLayout },
            component: () => import("@/views/periods/PeriodPlanNext.vue"),
        },
        {
            name: "configuration-organizations",
            path: "/configuration/organizations/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/configuration/organizations/OrganizationsConfigurationView.vue"),
            },
        },
        {
            name: "organizations",
            path: "/organizations-list",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/organizations/OrganizationsView.vue"),
            },
        },
        {
            name: "organization-create",
            path: "/organizations/create",
            meta: { requiresAuth: true, layout: AppLayout },
            component: () => import("@/views/organizations/OrganizationCreateView.vue"),
        },
        {
            name: "organization-read",
            path: "/organizations/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/organizations/OrganizationReadView.vue"),
            },
        },
        {
            name: "billing",
            path: "/billing",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/invoices/OrganizationsInvoicesView.vue"),
            },
        },
        {
            name: "billing-read",
            path: "/billing/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/invoices/organizations/OrganizationInvoiceReadView.vue"),
            },
        },
        {
            name: "configuration-establishments",
            path: "/configuration/establishments/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/configuration/establishments/EstablishmentsConfigurationView.vue"),
            },
        },
        {
            name: "establishments",
            path: "/establishments",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/establishments/EstablishmentsView.vue"),
            },
        },
        {
            name: "establishment-read",
            path: "/establishments/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/establishments/EstablishmentReadView.vue"),
            },
        },
        {
            name: "establishment-create",
            path: "/establishments/create",
            meta: { requiresAuth: true, layout: AppLayout },
            component: () => import("@/views/establishments/EstablishmentCreateView.vue"),
        },
        {
            name: "contact-support",
            path: "/contact-support",
            meta: { requiresAuth: true, layout: AppLayout },
            component: () => import("@/views/contact-support/ContactSupportView.vue"),
        },
        {
            name: "work-time",
            path: "/work-time",
            meta: { requiresAuth: true, layout: AppLayout },
            component: () => import("@/views/activities/work-time/WorkTimeView.vue"),
        },
        {
            path: "/error/organization",
            name: "organization-error",
            component: () => import("@/views/ErrorView.vue"),
            meta: { requiresAuth: false, layout: EmptyLayout },
            props: {
                title: "Organisation introuvable",
                description: "Cette organisation n'existe pas ou n'est plus accessible.",
                showBackToOrganizations: true,
            },
        },
        {
            path: "/managing",
            meta: { requiresAuth: true, layout: AppLayout },
            children: [
                {
                    path: "blueprints",
                    name: "blueprints-managing",
                    component: () => import("@/views/activities/blueprints/BlueprintsManagingView.vue"),
                },
                {
                    path: "blueprints/:id",
                    name: "blueprint-managing",
                    component: () => import("@/views/activities/blueprints/BlueprintManagingView.vue"),
                },
                {
                    path: "services/:id",
                    name: "service-managing",
                    component: () => import("@/views/activities/services/ServiceManagingView.vue"),
                },
                {
                    path: "prestations/:id",
                    name: "prestation-managing",
                    component: () => import("@/views/activities/prestations/PrestationManagingView.vue"),
                },
                {
                    path: "periods",
                    name: "periods-managing",
                    component: () => import("@/views/activities/periods/PeriodsManagingView.vue"),
                },
                {
                    path: "periods/:id",
                    name: "period-managing",
                    component: () => import("@/views/activities/periods/PeriodManagingView.vue"),
                },
            ],
        },
        {
            path: "/catalog",
            meta: { requiresAuth: true, layout: AppLayout },
            children: [
                {
                    path: "/catalog",
                    name: "catalog",
                    component: () => import("@/views/activities/catalog/CatalogView.vue"),
                },
                {
                    path: "blueprints/:id",
                    name: "blueprint-catalog",
                    component: () => import("@/views/activities/blueprints/BlueprintCatalogView.vue"),
                },
            ],
        },
        {
            path: "/attendance",
            meta: { requiresAuth: true, layout: AppLayout },
            children: [
                {
                    path: "services",
                    name: "services-attendance",
                    component: () => import("@/views/activities/services/ServicesAttendanceView.vue"),
                },
                {
                    path: "services/:id",
                    name: "service-attendance",
                    component: () => import("@/views/activities/services/ServiceAttendanceView.vue"),
                },
            ],
        },
        {
            path: "/holdings",
            meta: { requiresAuth: true, layout: AppLayout },
            children: [
                {
                    path: "services",
                    name: "services-holdings",
                    component: () => import("@/views/activities/services/ServicesHoldingsView.vue"),
                },
                {
                    path: "services/:id",
                    name: "service-holdings",
                    component: () => import("@/views/activities/services/ServiceHoldingsView.vue"),
                },
                {
                    path: "prestations/:id",
                    name: "prestation-holdings",
                    component: () => import("@/views/activities/prestations/PrestationHoldingsView.vue"),
                },
                {
                    path: "attendees",
                    name: "attendees-holdings",
                    component: () => import("@/views/activities/holdings/AttendeesHoldingsView.vue"),
                },
            ],
        },
        {
            path: "/:pathMatch(.*)*",
            redirect: to => {
                const authStore = useAuthStore();
                const isAuthenticated = authStore.authenticated;

                if (!isAuthenticated) {
                    return {
                        name: "login",
                        query: {
                            redirect: to.fullPath,
                            error: "page-not-found",
                        },
                    };
                }

                return {
                    name: "index",
                    query: {
                        error: "page-not-found",
                    },
                };
            },
        },
    ],
});

// Navigation guards
router.beforeEach(organizationGuard);
router.beforeEach(authGuard);

export default router;
